<template>
  <div role="group" class="form-group form-row">
    <label v-if="label" :for="name" class="col-form-label" :class="settings.classes.label">{{ $t(label) }}</label>
    <div class="input-group" :class="settings.classes.input">
      <MaskedInput ref="input" class="form-control" :class="{ 'is-invalid': !isValid }" v-model="text" v-bind="$attrs" v-on="listeners" :placeholder="$t(c_placeholder)" :mask="config.mask" :showMask="$attrs.showMask || false" :guide="false" />
      <div v-if="mask === 'NIT'" class="input-group-append">
        <span class="input-group-text">{{ _format(text, 'dv_col') }}</span>
      </div>
      <template v-if="!isValid">
        <div v-for="(error, index) in errors" :key="index" class="invalid-feedback">{{ error.message }}</div>
      </template>
    </div>
    <Label v-if="helper" class="col-sm-3 control-helper">{{ $t(helper) }}</Label>
  </div>
</template>

<script>
import MaskedInput from 'vue-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'

export default {
  name: 'AMaskedInput',
  inheritAttrs: true,
  components: {
    MaskedInput
  },
  watch: {
    async mask(to) {
      this.parseMask(to)
      await this.$nextTick()
      this.$forceUpdate()
    },
    async value(to) {
      this.text = '' + (to || '')
      this.parseMask(this.mask)
      await this.$nextTick()
      this.$forceUpdate()
    }
  },
  props: {
    value: {
      type: [String, Number]
    },
    mask: {
      type: [String, Array],
      required: true
    },
    label: {
      type: String
    },
    helper: {
      type: String
    },
    horizontal: {
      type: [String, Object]
    },
    placeholder: {
      type: String
    },

    isValid: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array
    }
  },
  computed: {
    name() {
      //return this.$attrs.name || this.$attrs.id
      return this.$attrs.name || this.$attrs.id || this.$vnode.data.model.expression.split('.').pop()
    },
    listeners() {
      return {
        ...this.$listeners,
        input: event => this.emit('input', event),
        change: event => this.emit('change', event)
      }
    }
  },
  data() {
    return {
      settings: { classes: { input: 'col-sm-9', label: 'col-sm-3' } },
      text: '' + (this.value || ''),
      c_placeholder: this.$attrs.placeholder || this.placeholder || this.label,
      config: {
        mask: []
      }
    }
  },
  created() {
    if (typeof this.horizontal === 'object') this.settings.classes = { ...this.settings.classes, ...this.horizontal }
    //console.log('FORMAT')
    //console.log(this._format(21321312312, 'nit'))
  },
  mounted() {
    this.parseMask(this.mask) // TODO: check if necessary
  },
  methods: {
    parseMask(mask) {
      switch (mask) {
        case 'ID':
        case 'NIT':
        case 'value':
          // TODO: calculate DV if NIT
          mask = createNumberMask({ prefix: '', thousandsSeparatorSymbol: '.', allowNegative: false }) //, integerLimit: 10000000000 })
          break
        case 'email':
          mask = emailMask
          break
        // TODO: array ?
        case 'dd/mm/yyyy':
        case 'yyyy/mm/dd':
        case 'dd-mm-yyyy':
        case 'yyyy-mm-dd':
        case 'dd/mm/yyyy HH:MM':
        case 'yyyy/mm/dd HH:MM':
        case 'dd-mm-yyyy HH:MM':
        case 'yyyy-mm-dd HH:MM':
          mask = createAutoCorrectedDatePipe(mask)
          break
        case 'date':
          //mask = [/0|1|2|3/, /\d/, '/', /0|1|2|3/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
          mask = [/\d/, /\d/, /\d/, /\d/, '/', /0|1/, /\d/, '/', /0|1|2|3/, /\d/]
          break
        case 'phone':
          mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
          break
      }
      this.config = { mask }
      return mask
    },
    emit(type, event) {
      if (event.target && this.mask === 'email') {
        this.text = event.target.value.toLowerCase()
        event.target.value = this.text
      }
      this.$emit(type, event)
    }
  }
}
</script>
